import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { resetPasswordFormTranslations } from "@portal-account/components/ResetPasswordForm/ResetPasswordForm.en.i18n";
import {
  ResetPasswordFormCircularProgress,
  ResetPasswordFormContainer,
  ResetPasswordFormCtaButton,
  ResetPasswordFormPasswordRequirements,
} from "@portal-account/components/ResetPasswordForm/ResetPasswordForm.styled";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { StyledLoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm.styled";
import { PortalPasswordInput } from "@portal-shared/components/PortalPasswordInput/PortalPasswordInput";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useResetPasswordMutation } from "@portal-shared/mutations/useResetPasswordMutation";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ResetPasswordFormValues {
  password: string;
}

interface ResetPasswordFormProps {
  recoveryToken: string;
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { recoveryToken } = props;
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const resetPasswordMutation = useResetPasswordMutation();

  const {
    handleSubmit,
    formState: { isSubmitted, errors },
    register,
  } = useForm<ResetPasswordFormValues>();

  const { translate } = useTranslations();

  const {
    tResetPasswordFormResetPassword,
    tResetPasswordFormPasswordRequired,
    tResetPasswordFormResettingPassword,
    tResetPasswordFormErrorCreatingPassword,
    tResetPasswordFormPassword,
    tResetPasswordFormPasswordRequirements,
  } = translate(resetPasswordFormTranslations);

  const passwordInputFormProps = register("password", {
    required: tResetPasswordFormPasswordRequired,
  });

  const onSubmit = handleSubmit(({ password }: ResetPasswordFormValues) => {
    if (errors.password) {
      return;
    }

    resetPasswordMutation.mutate(
      { password, recoveryToken },
      {
        onError: () => {
          flash.error(tResetPasswordFormErrorCreatingPassword);
        },
        onSuccess: () => {
          dispatch(analyticsEnableSendCustomerLoginEvent());
          navigate(customerHomePath());
        },
      }
    );
  });

  const ctaButtonDisabled = Boolean(
    isSubmitted && (errors.password || resetPasswordMutation.isPending)
  );

  const passwordErrorMessage = errors.password?.message;

  return (
    <ResetPasswordFormContainer>
      <StyledLoggedOutForm onSubmit={onSubmit}>
        <PortalPasswordInput
          label={tResetPasswordFormPassword}
          errorMessage={passwordErrorMessage}
          inputProps={{
            id: "password",
            ...passwordInputFormProps,
          }}
        />

        <ResetPasswordFormPasswordRequirements variant="caption">
          {tResetPasswordFormPasswordRequirements}
        </ResetPasswordFormPasswordRequirements>
        <ResetPasswordFormCtaButton
          data-tracking-click={{
            event: "Customer attempting to reset password",
          }}
          type="submit"
          disabled={ctaButtonDisabled}
          color="primary"
        >
          {resetPasswordMutation.isPending ? (
            <>
              <ResetPasswordFormCircularProgress color="inherit" size={14} />
              <RhTypography color="inherit" variant="inherit">
                {tResetPasswordFormResettingPassword}
              </RhTypography>
            </>
          ) : (
            tResetPasswordFormResetPassword
          )}
        </ResetPasswordFormCtaButton>
      </StyledLoggedOutForm>
    </ResetPasswordFormContainer>
  );
};
