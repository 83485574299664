import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { loginFormTranslations } from "@portal-account/components/LoginForm/LoginForm.en.i18n";
import {
  LoginFormCircularProgress,
  LoginFormCtaButton,
  LoginFormForm,
  LoginFormInput,
  LoginFormInputErrorMessage,
  LoginFormInputLabel,
} from "@portal-account/components/LoginForm/LoginForm.styled";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { PortalPasswordInput } from "@portal-shared/components/PortalPasswordInput/PortalPasswordInput";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useLoginMutation } from "@portal-shared/mutations/useLoginMutation";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SignInFormValues {
  email: string;
  password: string;
}

export const LoginForm = () => {
  const flash = useRhFlash();
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { removeCookie } = useEnrollCookies();
  const dispatch = useDispatch();
  const loginMutation = useLoginMutation();

  const {
    handleSubmit,
    register,
    formState: { isSubmitted, errors },
  } = useForm<SignInFormValues>();

  const {
    tLoginFormEmail,
    tLoginFormErrorInvalidEmailOrPassword,
    tLoginFormErrorInvalidInputEmail,
    tLoginFormErrorInvalidInputEmailRequired,
    tLoginFormLoggingInCustomer,
    tLoginFormPasswordRequired,
    tLoginFormPassword,
    tLoginFormSubmitCta,
  } = translate(loginFormTranslations);

  const onSubmit = handleSubmit(({ email, password }: SignInFormValues) => {
    if (errors.email || errors.password) {
      return;
    }

    loginMutation.mutate(
      { email, password },
      {
        onError: () => {
          flash.error(tLoginFormErrorInvalidEmailOrPassword);
        },
        onSuccess: () => {
          sessionStorage.removeItem(SESSION_STORAGE_PREMISE_ID_KEY);
          dispatch(analyticsEnableSendCustomerLoginEvent());
          removeCookie("rhProspectUuid");
          navigate(customerHomePath());
        },
      }
    );
  });

  const emailInputFormProps = register("email", {
    pattern: {
      message: tLoginFormErrorInvalidInputEmail,
      value: EMAIL_VALIDATION_REGEX,
    },
    required: tLoginFormErrorInvalidInputEmailRequired,
  });

  const passwordInputFormProps = register("password", {
    required: tLoginFormPasswordRequired,
  });

  const ctaButtonDisabled = Boolean(
    isSubmitted && (errors.email || errors.password || loginMutation.isPending)
  );

  const passwordErrorMessage = errors.password?.message;

  return (
    <LoginFormForm onSubmit={onSubmit}>
      <LoginFormInputLabel htmlFor="email">
        {tLoginFormEmail}
      </LoginFormInputLabel>
      <LoginFormInput
        {...emailInputFormProps}
        id="email"
        aria-invalid={Boolean(errors.email)}
      />
      <LoginFormInputErrorMessage>
        {errors.email && errors.email.message}
      </LoginFormInputErrorMessage>

      <PortalPasswordInput
        label={tLoginFormPassword}
        errorMessage={passwordErrorMessage}
        inputProps={{
          id: "password",
          ...passwordInputFormProps,
        }}
      />
      <LoginFormCtaButton
        data-tracking-click={{ event: "Customer attempting to sign in" }}
        type="submit"
        disabled={ctaButtonDisabled}
        color="primary"
      >
        {loginMutation.isPending ? (
          <>
            <LoginFormCircularProgress color="inherit" size={14} />
            <RhTypography color="inherit" variant="inherit">
              {tLoginFormLoggingInCustomer}
            </RhTypography>
          </>
        ) : (
          tLoginFormSubmitCta
        )}
      </LoginFormCtaButton>
    </LoginFormForm>
  );
};
