import { ReactComponent as EyeForbiddenIcon } from "@design-system/icons/EyeForbiddenIcon.svg";
import { ReactComponent as EyeIcon } from "@design-system/icons/EyeIcon.svg";
import { PortalPasswordInputTranslations } from "@portal-shared/components/PortalPasswordInput/PortalPasswordInput.en.i18n";
import {
  PortalPasswordInputContainer,
  PortalPasswordInputErrorMessage,
  PortalPasswordInputLabel,
  PortalPasswordInputStyled,
} from "@portal-shared/components/PortalPasswordInput/PortalPasswordInput.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, {
  ComponentPropsWithRef,
  KeyboardEvent,
  useRef,
  useState,
} from "react";

interface PasswordInputProps {
  errorMessage?: string;
  inputProps: ComponentPropsWithRef<"input">;
  label: string;
}

export const PortalPasswordInput = (props: PasswordInputProps) => {
  const { errorMessage, label, inputProps } = props;
  const { id } = inputProps;
  const [inputPasswordType, setInputPasswordType] = useState<
    "text" | "password"
  >("password");

  const eyeIconRef = useRef<SVGSVGElement | null>(null);
  const eyeIconForbiddenRef = useRef<SVGSVGElement | null>(null);
  const { translate } = useTranslations();

  const { tPortalPasswordInputHidePassword, tPortalPasswordInputShowPassword } =
    translate(PortalPasswordInputTranslations);

  const onToggleViewPasswordClick = () => {
    setInputPasswordType((currentState) => {
      return currentState === "password" ? "text" : "password";
    });
  };

  const onToggleViewPasswordKeyDown = (event: KeyboardEvent) => {
    if (event.key === " " || event.key === "Enter") {
      event.stopPropagation();
      setInputPasswordType((currentState) => {
        if (currentState === "password") {
          requestAnimationFrame(() => {
            eyeIconRef.current?.focus();
          });
          return "text";
        }
        requestAnimationFrame(() => {
          eyeIconForbiddenRef.current?.focus();
        });
        return "password";
      });
    }
  };

  const eyeIconCommonProps = {
    onClick: onToggleViewPasswordClick,
    onKeyDown: onToggleViewPasswordKeyDown,
    tabIndex: 0,
  };

  return (
    <div>
      <PortalPasswordInputLabel htmlFor={id}>{label}</PortalPasswordInputLabel>
      <PortalPasswordInputContainer>
        <PortalPasswordInputStyled
          type={inputPasswordType}
          aria-invalid={Boolean(errorMessage)}
          aria-describedby="password-error"
          {...inputProps}
        />
        {inputPasswordType === "text" ? (
          <EyeIcon
            {...eyeIconCommonProps}
            ref={eyeIconRef}
            aria-label={tPortalPasswordInputHidePassword}
          />
        ) : (
          <EyeForbiddenIcon
            {...eyeIconCommonProps}
            ref={eyeIconForbiddenRef}
            aria-label={tPortalPasswordInputShowPassword}
          />
        )}
      </PortalPasswordInputContainer>
      <PortalPasswordInputErrorMessage>
        {errorMessage ? (
          <span id="password-error" role="alert">
            {errorMessage}
          </span>
        ) : null}
      </PortalPasswordInputErrorMessage>
    </div>
  );
};
