import { Order } from "@common/models/Order.model";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as LeftChevron } from "@design-system/icons/LeftChevron.svg";
import { useOneClickRenewalPageOfferSnapshotAtom } from "@portal-guest/atoms/useOneClickRenewalPageOfferSnapshotAtom";
import { OneClickRenewalSummaryForm } from "@portal-guest/components/OneClickRenewalSummaryForm/OneClickRenewalSummaryForm";
import { oneClickRenewalSummaryPageTranslations } from "@portal-guest/pages/OneClickRenewalSummaryPage/OneClickRenewalSummaryPage.en.i18n";
import {
  OneClickRenewalSummaryContainer,
  StyledBackButtonContainer,
  StyledTitle,
} from "@portal-guest/pages/OneClickRenewalSummaryPage/OneClickRenewalSummaryPage.styled";
import { useOneClickRenewalQuery } from "@portal-guest/queries/useOneClickRenewalQuery";
import { oneClickRenewalsPath } from "@portal-guest/routes/routePaths";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { StyledPortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout.styled";
import { RenewalSuccess } from "@portal-shared/components/RenewalSuccess/RenewalSuccess";
import { RenewalSummaryPlanInfo } from "@portal-shared/components/RenewalSummaryPlanInfo/RenewalSummaryPlanInfo";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { signInPath } from "@portal-shared/routes/routePaths";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export const OneClickRenewalSummaryPage = () => {
  const params = useParams<{
    renewalToken: string;
  }>();
  const renewalToken = params.renewalToken as string;
  const [futureOrder, setFutureOrder] = useState<Order | null>(null);
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const [offerSnapshot] = useOneClickRenewalPageOfferSnapshotAtom();

  const {
    tOneClickRenewalsSummaryPageTitle,
    tOneClickRenewalsSummaryPageSuccessButtonText,
    tOneClickRenewalsSummaryPageBack,
  } = translate(oneClickRenewalSummaryPageTranslations);

  const renewalTokenQuery = useOneClickRenewalQuery({
    enabled: false,
    renewalToken,
  });

  // We only want to fetch this query once on component mount.
  // If not, if submit is successful, the renewalToken will be expired causing subsequent 404 refetch calls.
  useEffect(() => {
    renewalTokenQuery.refetch();
  }, []);

  if (!offerSnapshot) {
    return <Navigate to={oneClickRenewalsPath(renewalToken)} />;
  }

  if (futureOrder) {
    return (
      <StyledPortalPageLayout>
        <RenewalSuccess
          order={futureOrder}
          ctaPath={signInPath()}
          ctaText={tOneClickRenewalsSummaryPageSuccessButtonText}
        />
      </StyledPortalPageLayout>
    );
  }

  if (renewalTokenQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (renewalTokenQuery.isError) {
    return <Error500Page />;
  }

  if (!renewalTokenQuery.data.order) {
    return <Error500Page />;
  }

  const {
    order: currentOrder,
    address,
    communicationPreference,
    autopayEnabled,
  } = renewalTokenQuery.data;

  return (
    <PortalPageLayout>
      <OneClickRenewalSummaryContainer>
        <StyledTitle>
          <RhTypography variant="h1">
            {tOneClickRenewalsSummaryPageTitle}
          </RhTypography>
        </StyledTitle>

        <RenewalSummaryPlanInfo
          currentOrder={currentOrder}
          offerSnapshot={offerSnapshot}
          serviceAddress={address}
        />

        <OneClickRenewalSummaryForm
          communicationPreference={communicationPreference}
          offerSnapshot={offerSnapshot}
          setFutureOrder={setFutureOrder}
          renewalToken={renewalToken}
          autopay={autopayEnabled}
        />

        <StyledBackButtonContainer
          data-tracking-click={{
            event: "Customer navigating to one click renewals page",
          }}
          variant="text"
          onClick={() => navigate(oneClickRenewalsPath(renewalToken))}
        >
          <LeftChevron />
          {tOneClickRenewalsSummaryPageBack}
        </StyledBackButtonContainer>
      </OneClickRenewalSummaryContainer>
    </PortalPageLayout>
  );
};
