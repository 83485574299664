export const createPasswordFormTranslations = {
  tCreatePasswordFormCreatePassword: "Create Password",
  tCreatePasswordFormCreatingPassword: "Creating your password...",
  tCreatePasswordFormErrorCreatingPassword:
    "Password creation failed. Please meet the minimum requirements and try again.",
  tCreatePasswordFormPassword: "Password",
  tCreatePasswordFormPasswordRequired: "Password is required.",
  tCreatePasswordFormPasswordRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
};
