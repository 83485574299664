import { ajax } from "@common/services/ajax";
import {
  CreatePasswordRequest,
  LoginRequest,
  ResetPasswordRequest,
} from "@portal-shared/types/authnTypes";

const BASE_URL = "/api/portal/authn";

let isRefreshingToken = false;

export const authnApi = {
  createPassword: (data: CreatePasswordRequest): Promise<void> => {
    return ajax.post(`${BASE_URL}/create-password`, data);
  },
  login: (data: LoginRequest): Promise<void> => {
    return ajax.post(`${BASE_URL}/login`, data);
  },
  logout: (): Promise<void> => {
    return ajax.post(`${BASE_URL}/logout`, {});
  },
  refreshToken: (): Promise<void> => {
    /**
     * https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-double-rendering-in-development
     * In theory, this only happens in local since <StrictMode> renders components twice
     *
     * Avoid multiple calls at the same time
     * If two calls happen at the same time and the first call
     * succeeds first (therefore deleting previous tokens)
     * The second one will fail and log you out
     */
    if (isRefreshingToken) {
      return Promise.resolve();
    }

    isRefreshingToken = true;

    const ajaxCall = ajax.post(`${BASE_URL}/refresh-token`, {});

    ajaxCall.finally(() => {
      isRefreshingToken = false;
    });

    return ajaxCall as Promise<void>;
  },
  resetPassword: (data: ResetPasswordRequest): Promise<void> => {
    return ajax.post(`${BASE_URL}/reset-password`, data);
  },
};
