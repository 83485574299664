import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { myAccountCommunicationPreferencesModalTranslations } from "@portal-account/components/MyAccountCommunicationPreferencesModal/MyAccountCommunicationPreferencesModal.en.i18n";
import {
  BalancedText,
  LegalText,
  PaperlessFormButtonContainer,
  PaperlessModalContainer,
  PaperlessToggle,
} from "@portal-account/components/MyAccountCommunicationPreferencesModal/MyAccountCommunicationPreferencesModal.styled";
import { usePortalCookies } from "@portal-account/hooks/usePortalCookies";
import { Premise } from "@portal-account/models/Premise.model";
import { usePremiseCommunicationPreferencesMutation } from "@portal-account/mutations/usePremiseCommunicationPreferencesMutation";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import dayjs from "dayjs";
import React from "react";
import { useForm } from "react-hook-form";

interface MyAccountCommunicationPreferencesModalProps {
  handleClose: () => void;
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}

interface CommunicationPreferencesFormValues {
  paperless: boolean;
}

const ModalTitle = ({
  offerSnapshot,
  premise,
}: {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tMyAccountCommunicationPreferencesModalTitle,
    tMyAccountCommunicationPreferencesModalIfDisableWarningSubTitle,
    tMyAccountCommunicationPreferencesModalActionRequired,
    tMyAccountCommunicationPreferencesModalMovingToPaperless,
    tMyAccountCommunicationPreferencesModalUpgradeToPaperless,
  } = translate(myAccountCommunicationPreferencesModalTranslations);

  const {
    tMyAccountCommunicationPreferencesModalWhenDisabledWarningTitle,
    tMyAccountCommunicationPreferencesModalIfDisableWarningTitle,
  } = translateJsx({
    tMyAccountCommunicationPreferencesModalIfDisableWarningTitle: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
      ),
    },
    tMyAccountCommunicationPreferencesModalWhenDisabledWarningTitle: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
      ),
    },
  });

  if (premise.isOnLegacyEBill) {
    return (
      <div>
        <BalancedText variant="body1" color="textSecondary" $upperCase>
          {tMyAccountCommunicationPreferencesModalActionRequired}
        </BalancedText>
        <BalancedText variant="subtitle2" fontWeight={FontWeight.Bold}>
          {tMyAccountCommunicationPreferencesModalMovingToPaperless}
        </BalancedText>
        <BalancedText variant="body1" color="textSecondary">
          {tMyAccountCommunicationPreferencesModalUpgradeToPaperless}
        </BalancedText>
      </div>
    );
  }

  if (offerSnapshot.isAutoPayPaperlessDiscountOffer && premise.isPaperless) {
    return (
      <>
        <BalancedText
          color="error"
          variant="subtitle2"
          fontWeight={FontWeight.Bold}
        >
          {tMyAccountCommunicationPreferencesModalIfDisableWarningTitle}
        </BalancedText>
        <BalancedText variant="body1" color="textSecondary">
          {tMyAccountCommunicationPreferencesModalIfDisableWarningSubTitle}
        </BalancedText>
      </>
    );
  }

  if (offerSnapshot.isAutoPayPaperlessDiscountOffer && !premise.isPaperless) {
    return (
      <BalancedText
        variant="subtitle2"
        fontWeight={FontWeight.Bold}
        color="error"
      >
        {tMyAccountCommunicationPreferencesModalWhenDisabledWarningTitle}
      </BalancedText>
    );
  }

  return (
    <BalancedText variant="subtitle2" fontWeight={FontWeight.Bold}>
      {tMyAccountCommunicationPreferencesModalTitle}
    </BalancedText>
  );
};

export const MyAccountCommunicationPreferencesModal = (
  props: MyAccountCommunicationPreferencesModalProps
) => {
  const { premise, handleClose, offerSnapshot } = props;
  const { translate } = useTranslations();
  const flash = useRhFlash();
  const { iconPaperless } = useBrandImageUrls();
  const { setCookie } = usePortalCookies();

  const { register, handleSubmit } =
    useForm<CommunicationPreferencesFormValues>({
      defaultValues: {
        paperless: premise.isPaperless || premise.isOnLegacyEBill,
      },
    });

  const premiseCommunicationPreferenceMutation =
    usePremiseCommunicationPreferencesMutation();

  const {
    tMyAccountCommunicationPreferencesModalLegalText,
    tMyAccountCommunicationPreferencesModalCancelButtonText,
    tMyAccountCommunicationPreferencesModalConfirmButtonText,
    tMyAccountCommunicationPreferencesModalPaperlessLabel,
    tMyAccountCommunicationPreferencesModalModalCloseLabel,
    tMyAccountCommunicationPreferencesModalModalLabel,
    tMyAccountCommunicationPreferencesModalUpdateSuccess,
    tMyAccountCommunicationPreferencesModalUpdateFailure,
  } = translate(myAccountCommunicationPreferencesModalTranslations);

  const onSubmit = handleSubmit(
    (values: CommunicationPreferencesFormValues) => {
      premiseCommunicationPreferenceMutation.mutate(
        {
          paperless: values.paperless,
          premiseId: premise.id,
        },
        {
          onError: () => {
            flash.error(tMyAccountCommunicationPreferencesModalUpdateFailure);
            handleClose();
          },
          onSettled: () => {
            const sixtyDaysFromNow = dayjs().add(60, "day").toDate();
            const { cookieDomain } = brandInformation;

            setCookie("rhSwitchPrintToPaperlessModal", "hide", {
              domain: cookieDomain,
              expires: sixtyDaysFromNow,
            });
          },
          onSuccess: () => {
            flash.success(tMyAccountCommunicationPreferencesModalUpdateSuccess);
            handleClose();
          },
        }
      );
    }
  );

  return (
    <RhModal
      ariaCloseLabel={tMyAccountCommunicationPreferencesModalModalCloseLabel}
      ariaLabel={tMyAccountCommunicationPreferencesModalModalLabel}
      handleClose={handleClose}
      size={ModalWidths.sm}
    >
      <PaperlessModalContainer>
        <img src={iconPaperless} alt="" aria-hidden width={48} height={48} />

        <ModalTitle offerSnapshot={offerSnapshot} premise={premise} />

        <form onSubmit={onSubmit}>
          <RhCard noShadow>
            <PaperlessToggle
              dataTrackingClick={{ event: "Customer toggling paperless" }}
              inputProps={register("paperless")}
            >
              {tMyAccountCommunicationPreferencesModalPaperlessLabel}
            </PaperlessToggle>
            <LegalText variant="body2" color="textSecondary">
              {tMyAccountCommunicationPreferencesModalLegalText}
            </LegalText>
          </RhCard>

          <PaperlessFormButtonContainer>
            <RhButton
              data-tracking-click={{
                event:
                  "customer closed communication preferences modal without changing anything",
              }}
              type="button"
              onClick={handleClose}
              variant="outlined"
              fullWidth
            >
              {tMyAccountCommunicationPreferencesModalCancelButtonText}
            </RhButton>
            <RhButton
              data-tracking-click={{
                event: "customer switching communication preferences",
              }}
              type="submit"
              fullWidth
            >
              {tMyAccountCommunicationPreferencesModalConfirmButtonText}
            </RhButton>
          </PaperlessFormButtonContainer>
        </form>
      </PaperlessModalContainer>
    </RhModal>
  );
};
