import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useGetSignUpBillingPreferencesNextStep = () => {
  const {
    depositAmount,
    ssnRequired,
    creditEvaluation,
    nosRequired,
    additionalInformationRequired,
    emailConflictsWithExistingAccount,
  } = useSelector(selectSignUpState);

  const getBillingPreferencesNextStep = useCallback(
    ({ autopay }: { autopay: boolean }): SignUpStepType => {
      if (
        creditEvaluation === CreditScoreOutcome.manualReview &&
        !additionalInformationRequired
      ) {
        return "call-us";
      } else if (
        additionalInformationRequired ||
        emailConflictsWithExistingAccount
      ) {
        return "provide-additional-information";
      } else if (ssnRequired) {
        return "credit-check";
      } else if (nosRequired) {
        return "call-us";
      } else if (depositAmount) {
        return "deposit";
      } else if (!autopay) {
        return "add-ons";
      } else {
        return "payment";
      }
    },
    [
      additionalInformationRequired,
      creditEvaluation,
      depositAmount,
      emailConflictsWithExistingAccount,
      nosRequired,
      ssnRequired,
    ]
  );

  return { getBillingPreferencesNextStep };
};
