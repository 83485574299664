import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { ResetPasswordForm } from "@portal-account/components/ResetPasswordForm/ResetPasswordForm";
import { resetPasswordPageTranslations } from "@portal-account/pages/ResetPasswordPage/ResetPasswordPage.en.i18n";
import { ResetPasswordViaOktaForm } from "@portal-account/pages/ResetPasswordPage/ResetPasswordViaOktaForm";
import { useResetPasswordPageParams } from "@portal-account/pages/ResetPasswordPage/useResetPasswordPageParams";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const ResetPasswordPage = () => {
  const { token: recoveryToken } = useResetPasswordPageParams();

  const { translate } = useTranslations();

  const { tResetPasswordPageTitle } = translate(resetPasswordPageTranslations);

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", false],
  ]);

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerText={tResetPasswordPageTitle} />
      {portalAccountOktaBackendProxy.value ? (
        <ResetPasswordForm recoveryToken={recoveryToken} />
      ) : (
        <ResetPasswordViaOktaForm recoveryToken={recoveryToken} />
      )}
    </LoggedOutPageLayout>
  );
};
