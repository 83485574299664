import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const ResetPasswordFormContainer = styled.div`
  margin-top: 30px;
`;
export const ResetPasswordFormInputLabel = styled.label`
  color: ${CSSVariables.COLOR_GREY_600};
  display: block;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  padding-bottom: 0.25rem;
`;

export const ResetPasswordFormCtaButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

export const ResetPasswordFormCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const ResetPasswordFormPasswordRequirements = styled(RhTypography)`
  &.MuiTypography-root {
    display: block;
    margin-bottom: ${rhSpacingPx(4)};
    margin-top: ${rhSpacingPx(2)};
    text-align: center;
  }
`;
