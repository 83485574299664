import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const PortalPasswordInputContainer = styled.div`
  position: relative;
  > svg {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};
      outline-offset: 2px;
    }
  }
`;

export const PortalPasswordInputLabel = styled.label`
  color: ${CSSVariables.COLOR_GREY_600};
  display: block;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  padding-bottom: 0.25rem;
`;

export const PortalPasswordInputStyled = styled.input`
  border: 1px solid ${CSSVariables.COLOR_GREY_200};

  &[aria-invalid="true"] {
    border-color: ${CSSVariables.COLOR_ERROR_MAIN};
  }
  border-radius: 4px;
  font-size: 16px;
  height: 45px;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(1.5)};
  width: 100%;
  &:focus {
    border-color: transparent;
    outline: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};

    &[aria-invalid="true"] {
      outline-color: ${CSSVariables.COLOR_ERROR_MAIN};
    }
  }
`;

export const PortalPasswordInputErrorMessage = styled.div`
  color: ${CSSVariables.COLOR_ERROR_MAIN};
  height: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(0.5)};
`;
