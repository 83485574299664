export const resetPasswordFormTranslations = {
  tResetPasswordFormErrorCreatingPassword:
    "Password creation failed. Please meet the minimum requirements and try again.",
  tResetPasswordFormPassword: "Password",
  tResetPasswordFormPasswordRequired: "Password is required.",
  tResetPasswordFormPasswordRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tResetPasswordFormResetPassword: "Reset Password",
  tResetPasswordFormResettingPassword: "Resetting your password...",
};
