import { Order } from "@common/models/Order.model";
import {
  formatDateRange,
  formatDollarsToCents,
} from "@common/utils/dataFormatters";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { yourCurrentPlanSectionTranslations } from "@portal-account/components/YourCurrentPlanSection/YourCurrentPlanSection.en.i18n";
import {
  StyledBody,
  StyledBreakdown,
  StyledCard,
  StyledFeature,
  StyledFeatures,
  StyledFeaturesColumn,
  StyledPlanTitle,
} from "@portal-account/components/YourCurrentPlanSection/YourCurrentPlanSection.styled";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Premise } from "@portal-account/models/Premise.model";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { WelcomePacketLink } from "@portal-shared/components/WelcomePacketLink/WelcomePacketLink";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import React from "react";

type YourCurrentPlanSectionProps = {
  order: Order;
  premise: Premise;
};
export const YourCurrentPlanSection = ({
  order,
  premise,
}: YourCurrentPlanSectionProps) => {
  const { translate } = useTranslations();
  const {
    tYourCurrentPlanSectionViewPriceBreakdown,
    tYourCurrentPlanSectionEnergyCharge,
    tYourCurrentPlanSectionMonthToMonth,
    tYourCurrentPlanSectionYourPlanDetails,
    tYourCurrentPlanSectionFixedRate,
    tYourCurrentPlanSectionTimeDependentFixedRate,
    tYourCurrentPlanSectionVariableRate,
    tYourCurrentPlanSectionYourContractDocuments,
    tYourCurrentTermsOfService,
  } = translate(yourCurrentPlanSectionTranslations);
  const { iconCalendar, iconClock, iconPrice, iconLock, iconTag } =
    useBrandImageUrls();
  const [locale] = useLocaleAtom();

  const { data: offerSnapshot } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: order.offerSnapshotUuid,
  });

  const { addModal } = useAccountModals();
  const track = useTrackMyAccountEvents();

  if (!offerSnapshot) {
    return null;
  }

  const { isMonthToMonth } = offerSnapshot;

  const getRateInfo = () => {
    if (order.isMonthToMonth) {
      return (
        <>
          <img src={iconTag} alt="" aria-hidden />
          <RhTypography>{tYourCurrentPlanSectionVariableRate}</RhTypography>
        </>
      );
    }

    if (order.isTimeOfUse) {
      return (
        <>
          <img src={iconClock} alt="" aria-hidden />
          <RhTypography>
            {tYourCurrentPlanSectionTimeDependentFixedRate}
          </RhTypography>
        </>
      );
    }

    return (
      <>
        <img src={iconLock} alt="" aria-hidden />
        <RhTypography>{tYourCurrentPlanSectionFixedRate}</RhTypography>
      </>
    );
  };
  const trackingLocation = "Current Plan Section";

  return (
    <StyledCard title={tYourCurrentPlanSectionYourPlanDetails}>
      <StyledBody>
        <StyledPlanTitle variant="h1" fontWeight="Bold">
          {order.title}
        </StyledPlanTitle>
        <StyledFeatures>
          <StyledFeaturesColumn>
            <StyledFeature>
              <img src={iconCalendar} alt="" aria-hidden />
              <RhTypography>
                {isMonthToMonth
                  ? tYourCurrentPlanSectionMonthToMonth
                  : formatDateRange(order.startDate, order.endDate)}
              </RhTypography>
            </StyledFeature>
            <StyledFeature>
              {order.isTimeOfUse ? (
                <StyledBreakdown
                  onClick={() => {
                    addModal({
                      energyBreakdown: {
                        offerSnapshotUuid: offerSnapshot.uuid,
                      },
                    });

                    track({
                      action: ActionType.click,
                      event: MyAccountEvents.viewPriceBreakdown,
                      label: "Home page - Opened view price breakdown modal",
                    });
                  }}
                >
                  <img src={iconPrice} alt="" aria-hidden />
                  <RhTypography color="primary">
                    {tYourCurrentPlanSectionViewPriceBreakdown}
                  </RhTypography>
                </StyledBreakdown>
              ) : (
                <RhTypography>
                  {formatDollarsToCents(offerSnapshot.rhythmKwhRate, false)}
                  {tYourCurrentPlanSectionEnergyCharge}
                </RhTypography>
              )}
            </StyledFeature>
          </StyledFeaturesColumn>
          <StyledFeaturesColumn>
            <StyledFeature>{getRateInfo()}</StyledFeature>
            {!order.isMonthToMonth && (
              <WelcomePacketLink
                orderId={order.id}
                premiseId={premise.id}
                dataTrackingClick={{ location: trackingLocation }}
              >
                <StyledFeature>
                  <PDFIcon
                    width={20}
                    height={20}
                    color={CSSVariables.COLOR_PRIMARY_MAIN}
                  />
                  <RhTypography>
                    {tYourCurrentPlanSectionYourContractDocuments}
                  </RhTypography>
                </StyledFeature>
              </WelcomePacketLink>
            )}
            <TOSLink
              tosUrl={order.termsOfServiceLink(locale)}
              dataTrackingClick={{ location: trackingLocation }}
            >
              <StyledFeature>
                <PDFIcon
                  width={20}
                  height={20}
                  color={CSSVariables.COLOR_PRIMARY_MAIN}
                />
                <RhTypography color="primary">
                  {tYourCurrentTermsOfService}
                </RhTypography>
              </StyledFeature>
            </TOSLink>
          </StyledFeaturesColumn>
        </StyledFeatures>
      </StyledBody>
    </StyledCard>
  );
};
